import React from 'react'
import {MdCancel as RemoveIcon} from 'react-icons/md'
import {arrayMove, SortableContainer, SortableElement} from 'react-sortable-hoc'
import Button from '@components/Button'
import without from 'lodash/without'
import PropTypes from 'prop-types'
import {ArrayComponent} from 'simple-react-form'

import Item from './Item'

import './styles.module.css'

// Sortable Elements
const SortableItem = SortableElement(({item}) => <div>{item}</div>)

const SortableList = SortableContainer(({items, draggable}) => {
  return (
    <div>
      {items.map((item, index) => (
        <SortableItem disabled={!draggable} key={`item-${index}`} index={index} item={item} />
      ))}
    </div>
  )
})

export default class Array extends ArrayComponent {
  static propTypes = {
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
    ...ArrayComponent.propTypes,
    childrenClassName: PropTypes.string,
    draggable: PropTypes.bool,
    showRemoveButton: PropTypes.bool,
    useAccordion: PropTypes.bool,
    accordionLabel: PropTypes.string,
    showStyle: PropTypes.bool
  }

  static defaultProps = {
    ...ArrayComponent.defaultProps,
    childrenClassName: `os-s-array os-s-array-1`,
    draggable: true,
    showRemoveButton: true,
    useAccordion: false,
    accordionLabel: '',
    showStyle: true
  }

  removeItem(index) {
    const value = this.props.value || []
    const newArray = without(value, value[index])
    this.props.onChange(newArray)
  }

  componentWillUnmount() {
    const {autoSave = false} = this.props
    if (autoSave) this.props.onChange(this.props.value)
  }

  renderRemoveButton(index) {
    if (!this.props.showRemoveButton) return
    if (this.props.disabled) return
    return (
      <div
        className={
          this.props.useAccordion ? 'os-array-accordion-item-remove' : 'os-array-item-remove'
        }>
        <Button danger onClick={() => this.removeItem(index)}>
          <RemoveIcon />
        </Button>
      </div>
    )
  }

  renderAddButton() {
    if (!this.props.showAddButton) return
    if (this.props.disabled) return
    return <Button primary label={this.props.addLabel} onClick={() => this.addItem()} />
  }

  // this function return the specific field
  renderChildrenItem({item, index, children}) {
    if (this.props.useAccordion) {
      return (
        <Item
          index={index}
          defaultShow={index === 0}
          label={item.accordionLabel && item.accordionLabel.length > 0 ? item.accordionLabel : null}
          item={item}>
          <div className="os-array-accordion-item-content">
            {this.renderChildrenItemWithContext({index, children})}
          </div>
          {this.renderRemoveButton(index)}
        </Item>
      )
    }

    return (
      <div
        className={this.props.childrenClassName}
        key={`${this.props.fieldName}.${index}`}
        style={!this.props.draggable ? {cursor: 'initial'} : null}>
        <div className="os-array-item-content">
          {this.renderChildrenItemWithContext({index, children})}
        </div>
        {this.renderRemoveButton(index)}
      </div>
    )
  }

  // Render children according to elements inside value
  renderChildren() {
    const value = this.props.value || []
    if (this.props.autoAddItem && !this.props.disabled && value.length === 0) {
      value.push({})
    }
    const items = value.map((item, index) => {
      const children = this.getChildrenComponents(item, index)
      return this.renderChildrenItem({item, index, children})
    })
    const onSortEnd = ({oldIndex, newIndex}) =>
      this.props.onChange(arrayMove(value, oldIndex, newIndex))
    return (
      <SortableList
        helperClass={this.props.showStyle ? 'os-s-array-grabbing' : ''}
        distance={2}
        items={items}
        draggable={this.props.draggable}
        onSortEnd={onSortEnd}
      />
    )
  }

  render() {
    return (
      <div style={{marginTop: 20}}>
        <div>{this.props.label}</div>
        <div style={{color: 'red'}}>{this.props.errorMessage}</div>
        <div
          className={this.props.useAccordion ? 'os-array-accordion' : this.props.parentClassName}>
          {this.renderChildren()}
        </div>
        <div style={{marginTop: 10, marginBottom: 10}}>{this.renderAddButton()}</div>
      </div>
    )
  }
}
