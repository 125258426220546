import DateText from '@packages/justo-parts/lib/components/fields/DateText'
import NumberField from '@packages/justo-parts/lib/components/fields/numeral/Number'
import Select from '@packages/justo-parts/lib/components/fields/Select'
import Text from '@packages/justo-parts/lib/components/fields/Text'
import Textarea from '@packages/justo-parts/lib/components/fields/Textarea'

import ArrayComponent from './ArrayComponent'
import Checkbox from './Checkbox'
import DatePicker from './DatePicker'
import DateTime from './DateTime'
import File from './File'
import Money from './Money'
import MultipleCheckbox from './MultipleCheckbox'
// import DriversZones from './DriversZones'
// import FileManager from './FileManager'
// import Categories from './Categories'
// import Options from './Options'
// import MultipleMoney from './MultipleMoney'
// import MarkerArray from './MarkerArray'
// import Schedule from './Schedule'
// import DateArray from './DateArray'
// import SelectImage from './SelectImage'
// import Category from './Category'
// import Percentage from '@packages/justo-parts/lib/components/fields/numeral/Percentage'
import NextDateTime from './NextDateTime'
import ObjectField from './ObjectField'
import Phone from './Phone'
import Place from './Place'
// import Menus from './Menus'
// import ColorPicker from './ColorPicker'
// import WebsitesIds from './WebsitesIds'
// import Font from './Font'
// import Products from './Products'
// import Image from './Image'
// import StringArray from './StringArray'
// import InstagramUserId from './InstagramUserId'
// import ReceiverData from './ReceiverData'
// import Brand from './Brand'
// import User from './User'
// import Segment from './Segment'
// import MonthSelector from './MonthSelector'
// import InStoreCoupon from './InStoreCoupon'
// import Modifiers from './Modifiers'
// import City from './City'
// import Order from './Order'
// import OrderStatus from './OrderStatus'
// import SelectJustos from './SelectJustos'
// import PaymentType from './PaymentType'
// import Country from './Country'
// import Product from './Product'
// import AppCampaigns from './AppCampaigns'
import ReceiverDataCO from './ReceiverDataCO'
import SixDigitInput from './SixDigitInput'
import Store from './Store'
import Stores from './Stores'
// import WebhookTypes from './WebhookTypes'
// import customCOLegalID from './customCOLegalID'
// import customCOAddress from './customCOAddress'
// import ExternalCompanyIds from './ExternalCompanyIds'
// import StoresByWebsites from './StoresByWebsites'
// import SpecialDelivery from './SpecialDelivery'
// import TrackedDrivers from './TrackedDrivers'
// import UserCurrency from './UserCurrency'
// import HttpPostOptions from './HttpPostOptions'
// import CityWithEdit from './CityWithEdit'
// import PaymentMethods from './PaymentMethods'
// import CustomCOAddressBilling from './CustomCOAddressBilling'

const autoformFields = {
  // trackedDrivers: TrackedDrivers,
  // country: Country,
  // selectJustos: SelectJustos,
  // monthSelector: MonthSelector,
  // modifiers: Modifiers,
  // orderId: Order,
  // orderStatus: OrderStatus,
  phone: Phone,
  // percentage: Percentage,
  string: Text,
  // brand: Brand,
  textarea: Textarea,
  email: Text,
  // cityId: City,
  // fileManager: FileManager,
  file: File,
  // image: Image,
  money: Money,
  number: NumberField,
  array: ArrayComponent,
  plainObject: ObjectField,
  // dateArray: DateArray,
  // '[date]': DateArray,
  // segment: Segment,
  // schedule: Schedule,
  boolean: Checkbox,
  multipleCheckbox: MultipleCheckbox,
  date: DateText,
  datetime: DateTime,
  // markerArray: MarkerArray,
  sixDigit: SixDigitInput,
  // multipleMoney: MultipleMoney,
  select: Select,
  // categories: Categories,
  // category: Category,
  stores: Stores,
  store: Store,
  // storesByWebsites: StoresByWebsites,
  // options: Options,
  place: Place,
  // inStoreCoupon: InStoreCoupon,
  // colorPicker: ColorPicker,
  // '[string]': StringArray,
  // stringArray: StringArray,
  // selectImage: SelectImage,
  nextDateTime: NextDateTime,
  // menus: Menus,
  // websitesIds: WebsitesIds,
  // websites: WebsitesIds,
  // font: Font,
  // product: Product,
  // products: Products,
  // instagramUserId: InstagramUserId,
  // receiverData: ReceiverData,
  // user: User,
  // paymentType: PaymentType,
  // appCampaigns: AppCampaigns,
  receiverDataCO: ReceiverDataCO,
  // webhookTypes: WebhookTypes,
  // customCOLegalID,
  // customCOAddress,
  // externalCompanyIds: ExternalCompanyIds,
  // deliveryId: SpecialDelivery,
  // driversZones: DriversZones,
  // UserCurrency,
  datepicker: DatePicker
  // httpPostOptions: HttpPostOptions,
  // CityWithEdit,
  // paymentMethods: PaymentMethods,
  // customCOAddressBilling: CustomCOAddressBilling
}

export default autoformFields
